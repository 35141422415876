import { React, ReactDOM, $ } from '../../../common/3rd';
import { Envs, Swiper, Utils } from '../../../common/common';
import './changeAddress.scss';
import {
  provsData,
  citysData,
  distsData,
  newAreaData,
} from '../../../common/larea-data';
class ChangeAddress extends React.Component {
  constructor(context) {
    super();
    this.state = {
      isShow: false,
      param: {},
      formData: {}
    };
  }
  UNSAFE_componentWillMount() { }

  componentWillUnmount() { }
  componentDidMount() { }
  //省改变
  changeProvince(value) {
    console.log('省改变', value);
    if (!value || value == '0') return;
    let city = null;
    let sheng = null;
    if (value == '0') {
      sheng = value;
      this.setState({ sheng });
      return;
    }
    city = citysData[value];
    sheng = value;
    this.setState({ sheng, city }, () => {
      this.changeCity(this.state.city[0].v);
    })
  }
  //市改变
  changeCity(value) {
    console.log('市改变', value);
    console.log(this.state.city);
    if (value == '0' || !value) return;
    let region = null;
    let shi = null;
    region = distsData[value];
    shi = value;
    this.setState({ shi, region }, () => {
      this.changeRegin(this.state.region[0].v)
    });
  }
  //区改变
  changeRegin(value) {
    console.log('区改变', value);
    console.log(this.state.region);
    if (value == '0' || !value) return;
    let qu = null;
    qu = value;
    this.state.formData.province = this.state.sheng.toString();
    this.state.formData.city = this.state.shi.toString();
    this.state.formData.county = value.toString();
    this.setState({ qu });

  }
  showAlert(param) {
    console.log('alert---showAlert');
    this.setState({
      isShow: true,
      param: param
    })
    // this.state.formData.province = param.province
    // this.state.formData.city = param.city
    // this.state.formData.county = param.county
    // this.state.formData.address = param.address
  }
  // 关闭弹窗
  closeAlert(type) {
    console.log('alert---closeAlert');
    if (type == 'confirm') {
      let { param } = this.state
      let province = $('.alert-sheng').val()
      let city = $('.alert-shi').val()
      let county = $('.alert-qu').val()
      let address = $('.alert-address').val()
      console.log('123123123123123', province, city, address);
      if (province && city && county && address) {
        param.province = province
        param.city = city
        param.county = county
        param.address = address
        param.edorAddressFlag = 'N' //地址是否与核心返回一致.Y:是 N:否
        Utils.setAppntCheckInfo(param)
      }
    }
    this.setState({ isShow: false })
  }
  render() {
    let { isShow, formData } = this.state
    return (
      <div className={isShow ? 'isShow' : 'noShow'}>
        <div className='item-alert'>
          <div className='item-content'>
            <div className="item-address">
              <div className='lable-box'>所在省市:</div>
              <select className="alert-sheng" onChange={(e) => { this.changeProvince(e.target.value); }} value={formData.province} >
                <option value="">请选择</option>
                {provsData.map((v, i) => {
                  return (
                    <option key={i} value={v.v}>
                      {v.l}
                    </option>
                  );
                })}
              </select>
              <select
                id="shi"
                onChange={(e) => this.changeCity(e.target.value)}
                value={formData.city}
                className="alert-shi"
              >
                <option value="">请选择</option>
                {this.state.city &&
                  this.state.city.map((v, i) => {
                    return (
                      <option key={i} value={v.v}>
                        {v.l}
                      </option>
                    );
                  })}
              </select>
              <select
                onChange={(e) => this.changeRegin(e.target.value)}
                value={formData.county}
                className="alert-qu"
              >
                <option value="">请选择</option>
                {this.state.region &&
                  this.state.region.map((v, i) => {
                    return (
                      <option key={i} value={v.v}>
                        {v.l}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="item-address">
              <div className='lable-box'>详细地址:</div>
              <input type="text" className="alert-address" value={formData.address} placeholder="街道门牌、楼层房间号等信息" />
            </div>
          </div>
          <div className="item-btn">
            <div className='btn-no' onClick={() => { this.closeAlert('') }}>取消</div>
            <div className='btn-yes' onClick={() => { this.closeAlert('confirm') }}>确定</div>
          </div>
        </div>
        {/* <div className="addressMask" onClick={() => { this.closeAlert() }}></div> */}
        <div className="addressMask" ></div>
      </div>
    );
  }
}

let container = $('#alertAddress-container');
if (container.length === 0) {
  container = $('<div id="alertAddress-container">').appendTo(document.body);
}
let changeAddress = ReactDOM.render(
  <ChangeAddress />,
  document.getElementById('alertAddress-container')
);

export default changeAddress;
